import PropTypes from 'prop-types';
import React, { Component } from 'react';
import GridViewIcon from '@assets/svg/svgui/grid-view-icon.svg'
import ListViewIcon from '@assets/svg/svgui/list-view-icon.svg'

class FilterBarTabs extends Component {
  static propTypes = {
    listViewType: PropTypes.string,
    setListViewType: PropTypes.func,
  }

  componentDidMount = () => {
    this.positionTabIndicator();
  }

  componentDidUpdate = () => {
    this.positionTabIndicator();
  }

  positionTabIndicator = () => {
    if ( this.tabIndicatorRef ) {
      setTimeout(() => {
        const domRef = this.props.listViewType === 'grid' ? this.gridStyleRef : this.listStyleRef;

        const indicatorStyle = {
    			width: domRef.clientWidth + 'px',
    			transform: 'translateX(' + domRef.offsetLeft + 'px)'
    		};

        this.tabIndicatorRef.style.width = indicatorStyle.width;
        this.tabIndicatorRef.style.transform = indicatorStyle.transform;
      }, 0);
    }
  }

  setViewType = (type) => {
		this.props.setListViewType(type);
	}

  render() {
    return (
      <ul className="events-sort-bar__display-list">
        <div className="events-sort-bar__indicator-wrapper" ref={(ref) => { this.tabIndicatorRef = ref; }}>
          <div className="events-sort-bar__indicator"></div>
        </div>
        <li
          ref={(ref) => { this.listStyleRef = ref; }}
          className={`${this.props.listViewType === 'list' ? 'events-sort-bar__filter-item--selected' : 'events-sort-bar__filter-item'}`}
          onClick={() => this.setViewType('list')}>
          <span>{'LIST'}</span>
          <div className="display-list__icon">
            <ListViewIcon
              className="display-list__icon-svg"
              style={{ width: 12, height: 10 }}
            />
          </div>
        </li>
        <li
          ref={(ref) => { this.gridStyleRef = ref; }}
          className={`${this.props.listViewType === 'grid' ? 'events-sort-bar__filter-item--selected' : 'events-sort-bar__filter-item'}`}
          onClick={() => this.setViewType('grid')}>
            <div className="display-list__icon">
              <GridViewIcon
                className="display-list__icon-svg"
                style={{ width: 10, height: 10 }}
              />
            </div>
          <span>{'GRID'}</span>
        </li>
      </ul>
    );
  }
}


export default FilterBarTabs;
