import PropTypes from "prop-types";
import React, { Component } from "react";
import MobileMenuCloseIcon from "@assets/svg/svgui/mobile-menu-close-icon.svg";
import { renderFilterCheckboxes } from "@utils/render-filter-checkboxes";
import { GA } from "@constants/config";
import { isWindowDefined } from "../../assets/third-party/blast";

class CategoriesFilter extends Component {
  static propTypes = {
    data: PropTypes.array,
    setCategory: PropTypes.func.isRequired,
  };

  componentDidMount = () => {
    if (isWindowDefined) {
      window.ga &&
        window.ga(
          "send",
          "event",
          GA.CATEGORY.filter,
          GA.ACTIONS.click,
          GA.EVENTS.category_filter
        );
    }
  };

  onGenreChange = (e) => {
    this.props.setCategory({
      type: "category",
      value: e.target.value,
      selected: e.target.checked,
    });
    window &&
      window.ga &&
      window.ga(
        "send",
        "event",
        GA.CATEGORY.filter,
        e.target.checked ? GA.ACTIONS.select : GA.ACTIONS.deselect,
        e.target.value
      );
  };

  renderFilters(type) {
    const match = this.props.data.filter((c) => {
      return String(c.label).toUpperCase() === type;
    });

    if (match.length > 0 && match[0].subcategories.length > 0) {
      return renderFilterCheckboxes(
        match[0].subcategories,
        this.onGenreChange,
        true
      );
    }

    return null;
  }

  render() {
    const { withCloseIcon, onClose = () => {} } = this.props;

    return (
      <div className="row filter-bar__panel">
        {withCloseIcon && (
          <div className="filter-bar__panel__tablet_menu_filter_close">
            <div className="SVGInline" onClick={onClose}>
              <MobileMenuCloseIcon style={{ width: 40, height: 40 }} />
            </div>
          </div>
        )}
        <div
          className="col s12 m12 l8 col--no-margin-bottom col--no-margin-top filter-bar__panel__genres"
          style={{ paddingLeft: 40 }}
        >
          <div className="filter-section__caption">GENRES</div>
          <div className="filter-section__genres">
            {this.renderFilters("GENRES")}
          </div>
        </div>
        <div className="col s12 m12 l4 col--no-margin-bottom col--no-margin-top filter-bar__panel__categories">
          <div className="filter-section__caption">OTHER CATEGORIES</div>
          <div className="filter-section__other">
            {this.renderFilters("OTHER CATEGORIES")}
          </div>
        </div>
      </div>
    );
  }
}

export default CategoriesFilter;
