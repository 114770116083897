import React, { Component } from "react";
import { connect } from "react-redux";
import { denormalize } from "normalizr";
import { updateFilter } from "@actions/update-filter";
import { setListViewType } from "@actions/ui";
import SortBarItem from "@components/events/sort-bar-item";
import EventsViewToggle from "@components/events/events-view-toggle";
import { GA } from "@constants/config";
import { USER_CURATOR, USER_SCHEMA } from "@constants/config";
import { currentLocation } from "@selectors/filters";

class EventsSortBar extends Component {
  componentDidMount = () => {
    setTimeout(() => {
      this.forceUpdate();
    }, 200);
  };

  renderFilterItems = () => {
    return this.props.sortFilters.map((filter, i) => {
      return (
        <SortBarItem
          key={"sb-item-" + i}
          tabIndicator={this.tabIndicatorRef}
          setSort={this.props.updateFilter}
          browser={this.props.browser}
          {...filter}
        />
      );
    });
  };

  getCurators = () => {
    const { users, currentRegion } = this.props;
    const denormalizedUsers = denormalize(
      { users: users.result },
      { users: [USER_SCHEMA] },
      users.entities
    );

    return denormalizedUsers.users
      .filter(
        (user) =>
          user.role === USER_CURATOR && user.region === currentRegion.value
      )
      .map((user) => {
        user.label = "";
        if (user.name) {
          user.label = user.name;
        } else {
          user.label += user.first_name ? user.first_name + " " : "";
          user.label += user.last_name ? user.last_name : "";
        }

        user.route = user.username ? "/" + user.username : "/" + user.id;

        return user;
      });
  };

  render() {
    return (
      <div className="container container__events--sort-bar container__fullwidth">
        <div className="container">
          <div className="row row--no-margin-bottom">
            <div className="col s12 col--no-margin-bottom">
              <div className="events-sort-bar clearfix">
                <ul className="events-sort-bar__filter-list" role="tablist">
                  <div
                    className="events-sort-bar__indicator-wrapper"
                    ref={(ref) => {
                      this.tabIndicatorRef = ref;
                    }}
                  >
                    <div className="events-sort-bar__indicator"></div>
                  </div>
                  {this.renderFilterItems()}
                </ul>
                <EventsViewToggle
                  setListViewType={this.props.setListViewType}
                  listViewType={this.props.listViewType}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    sortFilters: state.eventFilters.sort_filters,
    currentRegion: currentLocation(state),
    users: state.eventUsers.data,
    listViewType: state.ui.listViewType,
    browser: state.browser,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateFilter: (data) => {
      window &&
        window.ga &&
        window.ga(
          "send",
          "event",
          GA.CATEGORY.filter,
          GA.ACTIONS.click,
          data.value || GA.EVENTS.all_events
        );
      dispatch(updateFilter(data));
    },
    setListViewType: (type) => {
      dispatch(setListViewType(type));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EventsSortBar);
