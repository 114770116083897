import PropTypes from 'prop-types'
import React, { Component } from 'react'

import EventTile from '@components/global/tiles/EventTile'
import { STANDARD_TILE } from '@constants/content-tile-types'

class EventsGridModule extends Component {
  static propTypes = {
    events: PropTypes.array,
    headline: PropTypes.string,
    moduleClass: PropTypes.string,
    description: PropTypes.string,
    sidebarModule: PropTypes.element,
    categoryFilters: PropTypes.array
  }

  renderEvents = () => {
    const { events } = this.props
    const eventsArr = events.map((event, i) => {
      return (
        <EventTile
          key={String(event.id + i)}
          type={STANDARD_TILE}
          column="col s12 m6 l4 xl3"
          categoryFilters={this.props.categoryFilters}
          {...event}
        />
      )
    })

    return eventsArr
  }

  render() {
    return <div className="row">{this.renderEvents()}</div>
  }
}

export default EventsGridModule
