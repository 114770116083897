import PropTypes from "prop-types";
import React, { Component } from "react";
import { renderFilterCheckboxes } from "@utils/render-filter-checkboxes";
import { GA } from "@constants/config";
import { isWindowDefined } from "../../assets/third-party/blast";

class DatesFilter extends Component {
  static propTypes = {
    data: PropTypes.array,
    setDates: PropTypes.func.isRequired,
  };

  componentDidMount = () => {
    if (isWindowDefined) {
      window.ga &&
        window.ga(
          "send",
          "event",
          GA.CATEGORY.filter,
          GA.ACTIONS.click,
          GA.EVENTS.date_filter
        );
    }
  };

  onDatesChange = (e) => {
    this.props.setDates({
      type: "dates",
      value: e.target.value,
      selected: e.target.checked,
    });
    window &&
      window.ga &&
      window.ga(
        "send",
        "event",
        GA.CATEGORY.filter,
        e.target.checked ? GA.ACTIONS.select : GA.ACTIONS.deselect,
        e.target.value
      );
  };

  renderDates = () => {
    return renderFilterCheckboxes(this.props.data, this.onDatesChange, true);
  };

  render() {
    return (
      <div className="row filter-bar__panel">
        <div className="col s12 m12 l8" style={{ paddingLeft: 40 }}>
          <div className="filter-section__caption">{"SPECIFIC DATE"}</div>
          <div className="filter-section__specific-dates">
            {this.renderDates()}
          </div>
        </div>
      </div>
    );
  }
}

export default DatesFilter;
