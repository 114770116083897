import _intersection from "lodash/intersection";
import {
  EVENT_GRADE_FEATURED,
  EVENT_GRADE_RECOMMENDED,
} from "@constants/config";

export const checkEventGradeMatching = (event, filters) => {
  const sortGrade = filters.sort_filters.filter((filter) => filter.selected);

  if (sortGrade.length > 0) {
    if (sortGrade[0].value === "featured") {
      if (event.event_grade < EVENT_GRADE_FEATURED) {
        return false;
      }
    } else if (sortGrade[0].value === "recommended") {
      if (
        event.event_grade < EVENT_GRADE_RECOMMENDED ||
        event.event_grade >= EVENT_GRADE_FEATURED
      ) {
        return false;
      }
    }
  }

  return true;
};

export const checkEventLocationMatching = (
  event,
  eventTags,
  locationFilters
) => {
  if (locationFilters.length === 1) {
    const matchingLocationTags = _intersection(locationFilters, eventTags);
    if (matchingLocationTags.length > 0) {
      return true;
    }
  } else if (locationFilters.length > 1) {
    const matchingLocationTags = _intersection(locationFilters, eventTags);
    if (matchingLocationTags.length > 1) {
      return true;
    }
  }

  return false;
};
