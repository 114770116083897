import PropTypes from 'prop-types';
import React, { Component } from 'react';

import FilterDates from '@assets/svg/svgui/filter-dates.svg'
import FilterLocations from '@assets/svg/svgui/filter-location.svg'
import FilterCategories from '@assets/svg/svgui/filter-categories.svg'
import FilterBarCarrot from '@assets/svg/svgui/filter-bar-carrot.svg'

class FilterBarTabs extends Component {

  static propTypes = {
    browser: PropTypes.object,
    toggle: PropTypes.func,
    currentLocation: PropTypes.string,
    selectedDates: PropTypes.array,
    selectedCategories: PropTypes.array,
    activeTab: PropTypes.string,
  }

  constructor(props) {
    super(props);
    this.state = {
      fadeOff: false
    };
  }

  componentDidMount = () => {}
  componentDidUpdate = () => {
    if (
      this.categoryTabLabelContent && this.categoryTabLabelContent.offsetWidth > this.categoryTabLabel.offsetWidth &&
      !this.state.fadeOff
    ) {
      this.setState({
        fadeOff: true
      });
    } else if (
      this.categoryTabLabelContent && this.categoryTabLabelContent.offsetWidth <= this.categoryTabLabel.offsetWidth &&
      this.state.fadeOff
    ) {
      this.setState({
        fadeOff: false
      });
    }
  }

  onClick = (tabName) => {
    this.props.toggle(tabName);
  }

  getSelectedDates = () => {
    if ( this.props.browser.lessThan.medium ) return 'Dates';

    if ( this.props.selectedDates.length > 0 ) {
      let dateString = '';
      for ( const d of this.props.selectedDates ) {
        if ( dateString !== '' ) dateString += ' • ';
        dateString += d.label;
      }

      return dateString;
    }

    return 'ALL DATES';
  }

  getSelectedCategories = () => {
    if ( this.props.browser.lessThan.medium ) return 'Categories';

    if ( this.props.selectedCategories.length > 0 ) {
      let dateString = '';
      for ( const d of this.props.selectedCategories ) {
        if ( dateString !== '' ) dateString += '  •  ';
        dateString += '<span class="category-pill">' + d.label + '</span>';
      }

      return dateString;
    }

    return '<span class="category-pill">ALL CATEGORIES</span>';
  }

  render() {
    return (
      <div className="filter-bar__tabs">
        <div className={`filter-bar__tab-item ${this.props.activeTab === 'location' ? 'active' : ''}`} onClick={() => this.onClick('location')}>
          <div className='filter-bar__tab-item-icon'>
            <FilterLocations
              style={{ height: 20, width: 16 }}
              className="filter-bar__tab-item-icon-svg"
            />
          </div>
          { this.props.browser.lessThan.medium ? (
            <div className="filter-bar__tab-item-label">
              {'Location'}
            </div>
          ) : (
            <div className="filter-bar__tab-item-label">
              {this.props.currentLocation}
            </div>
          )}
          { this.props.browser.greaterThan.mobile &&
            <div className={`filter-bar__tab-item-carrot ${this.props.activeTab === 'location' ? 'active' : ''}` }>
              <FilterBarCarrot
                className={`filter-bar__tab-item-carrot-svg ${this.props.activeTab === 'location' ? 'active-svg' : ''}` }
                style={{ height: 10, width: 17 }}
              />
            </div>
          }
        </div>
        <div className={`filter-bar__tab-item ${this.props.activeTab === 'dates' ? 'active' : ''}`} onClick={() => this.onClick('dates')}>
          <div className="filter-bar__tab-item-icon">
            <FilterDates
              className="filter-bar__tab-item-icon-svg"
              style={{ height: 20, width: 20 }}
            />
          </div>
          <div className="filter-bar__tab-item-label">
            {this.getSelectedDates()}
          </div>
          { this.props.browser.greaterThan.mobile &&
            <div className={`filter-bar__tab-item-carrot ${this.props.activeTab === 'dates' ? 'active' : ''}` }>
              <FilterBarCarrot
                className={`filter-bar__tab-item-carrot-svg ${this.props.activeTab === 'dates' ? 'active-svg' : ''}` }
                style={{ height: 10, width: 17 }}
              />
            </div>
          }
        </div>
        <div className={`filter-bar__tab-item ${this.props.activeTab === 'categories' ? 'active' : ''}`} onClick={() => this.onClick('categories')}>
          <div className='filter-bar__tab-item-icon'>
            <FilterCategories
              className="filter-bar__tab-item-icon-svg"
              style={{ height: 20, width: 20 }}
            />
          </div>
          <div
            className={`filter-bar__tab-item-label ${this.state.fadeOff ? 'fade-off' : ''}`}
            ref={(ref) => { this.categoryTabLabel = ref; }}>
            { this.props.browser.lessThan.medium ? (
              <span>{'Categories'}</span>
            ) : (
              <span
                className="filter-bar__tab-item-label__content"
                ref={(ref) => { this.categoryTabLabelContent = ref; }}
                dangerouslySetInnerHTML={{ __html: this.getSelectedCategories() }}></span>
            )}
          </div>
          { this.props.browser.greaterThan.mobile &&
            <div className={`filter-bar__tab-item-carrot ${this.props.activeTab === 'categories' ? 'active' : ''}` }>
              <FilterBarCarrot
                className={`filter-bar__tab-item-carrot-svg ${this.props.activeTab === 'categories' ? 'active-svg' : ''}` }
                style={{ height: 10, width: 17 }}
              />
            </div>
          }
        </div>
      </div>
    );
  }
}


export default FilterBarTabs;
