import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import { normalize } from "normalizr";
import { updateFilter } from "@actions/update-filter";
import { toggleFilterBar } from "@actions/ui";
import FilterBarTabs from "@components/events/filter-bar-tabs";
import { getActiveLocations, currentLocation } from "@selectors/filters";
import { CATEGORY_SCHEMA } from "@constants/config";
import LocationFilter from "@components/events/location-filter";
import DatesFilter from "@components/events/dates-filter";
import CategoriesFilter from "@components/events/categories-filter";

class EventsFilterBar extends Component {
  static propTypes = {
    // STORE PROPS
    regions: PropTypes.array,
    dates: PropTypes.array,
    categories: PropTypes.array,
    filterTab: PropTypes.string,
    browser: PropTypes.object,
    platform: PropTypes.object,
    // STORE FUNCTIONS
    updateFilter: PropTypes.func,
    toggleFilterBar: PropTypes.func,
  };

  constructor(props) {
    super(props);
    this.filterOptions = undefined;
    this.inlineStyle = {};
    this.state = {
      activeTab: "",
    };

    const { browser } = props;
    const isMobile = !browser.greaterThan.mobile;
    this.isTablet = !isMobile && !browser.greaterThan.large;
  }

  componentDidUpdate = (prevProps, prevState) => {
    const { activeTab } = this.state;
    // Check if device is tablet and All Categories is selected from footer
    const FILTER_BAR_DEFAULT_HEIGHT =
      this.isTablet && activeTab === "categories" ? 147 : 70;

    if (
      prevState.activeTab !== this.state.activeTab ||
      prevProps.regions !== this.props.regions
    ) {
      if (
        this.filterOptions &&
        this.filterOptions.clientHeight > FILTER_BAR_DEFAULT_HEIGHT &&
        this.state.activeTab !== ""
      ) {
        this.inlineStyle = {
          transform:
            "translateY(calc(100% - " +
            (FILTER_BAR_DEFAULT_HEIGHT + this.filterOptions.clientHeight) +
            "px))",
          height: "100%",
        };
      } else {
        this.inlineStyle = {};
      }

      this.forceUpdate();
    }

    if (
      this.props.filterTab !== "" &&
      this.props.filterTab !== prevProps.filterTab
    ) {
      this.toggle(this.props.filterTab);
    }

    if (this.state.activeTab === "" && prevState.activeTab !== "") {
      document.body.classList.remove("lock-scroll");
    } else if (this.state.activeTab !== "" && prevState.activeTab === "") {
      document.body.classList.add("lock-scroll");
    }
  };

  componentWillUnmount = () => {
    this.props.toggleFilterBar("");
    document.body.classList.remove("lock-scroll");
  };

  toggle = (tabName) => {
    if (this.state.activeTab === tabName) {
      this.props.toggleFilterBar("");
    }
    this.setState(
      Object.assign({}, this.state, {
        activeTab: tabName === this.state.activeTab ? "" : tabName,
      })
    );
  };

  close = () => {
    this.setState(
      Object.assign({}, this.state, {
        activeTab: "",
      })
    );
    this.props.toggleFilterBar("");
  };

  getClassNames = () => {
    if (this.state.activeTab !== "") {
      return `${
        this.props.platform.IS_SAFARI
          ? "container__events-filter-bar--disable-transition"
          : ""
      } container container__events-filter-bar--open container__fullwidth`;
    }

    return `${
      this.props.platform.IS_SAFARI
        ? "container__events-filter-bar--disable-transition"
        : ""
    } container container__events-filter-bar container__fullwidth`;
  };

  updateFilter = (data) => {
    this.props.updateFilter(data);
  };

  getCurrentLocation = () => {
    const { currentRegion } = this.props;
    if (currentRegion) return currentRegion.value;
    return "WHERE";
  };

  getSelectedDates = () => {
    const { dates } = this.props;
    return dates.filter((l) => l.selected);
  };

  getSelectedCategories = () => {
    if (!this.props.categories || this.props.categories.length <= 0) return [];

    const normalizedCategories = normalize(this.props.categories, [
      CATEGORY_SCHEMA,
    ]);
    const subCategories = Object.keys(
      normalizedCategories.entities.subcategories
    );
    const s = subCategories
      .filter((subCategory) => {
        const subCat = normalizedCategories.entities.subcategories[subCategory];
        if (subCat.selected) return true;
        return false;
      })
      .map((key) => normalizedCategories.entities.subcategories[key]);

    return s;
  };

  getMobileHeaderCaption = () => {
    if (this.state.activeTab === "dates") {
      return "Select Date";
    } else if (this.state.activeTab === "location") {
      return "Select Location";
    } else if (this.state.activeTab === "categories") {
      return "Select Categories";
    }

    return "";
  };

  renderFilter = () => {
    switch (this.state.activeTab) {
      case "location": {
        return (
          <LocationFilter
            data={this.props.regions}
            setLocation={this.updateFilter}
          />
        );
      }
      case "dates": {
        return (
          <DatesFilter data={this.props.dates} setDates={this.updateFilter} />
        );
      }
      case "categories": {
        return (
          <CategoriesFilter
            withCloseIcon={this.isTablet}
            onClose={this.close}
            data={this.props.categories}
            setCategory={this.updateFilter}
          />
        );
      }
      default: {
        return null;
      }
    }
  };

  render() {
    return (
      <div className={this.getClassNames()} style={this.inlineStyle}>
        <div
          className="container__events-filter-bar__backdrop"
          onClick={() => this.close()}
        />
        <div className="container container__fullwidth container__filter-bar-tabs">
          <div className="row row--no-margin-bottom">
            <div className="col s12 col--no-margin-bottom col--no-margin-top">
              <FilterBarTabs
                browser={this.props.browser}
                toggle={this.toggle}
                activeTab={this.state.activeTab}
                currentLocation={this.getCurrentLocation()}
                selectedDates={this.getSelectedDates()}
                selectedCategories={this.getSelectedCategories()}
              />
            </div>
          </div>
        </div>
        <div
          className="container__filter-options all_categories_page"
          ref={(ref) => {
            this.filterOptions = ref;
          }}
        >
          {this.renderFilter()}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    browser: state.browser,
    platform: state.platform,
    categories: state.eventFilters.categories,
    dates: state.eventFilters.dates,
    regions: getActiveLocations(state),
    currentRegion: currentLocation(state),
    filterTab: state.ui.filterTab,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateFilter: (data) => {
      dispatch(updateFilter(data));
    },
    toggleFilterBar: (filterTab) => {
      dispatch(toggleFilterBar(filterTab));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EventsFilterBar);
