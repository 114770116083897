import React, { Component } from "react";
import { connect } from "react-redux";
import { toggleFilterBar } from "@actions/ui";

class EventsBreadCrumbBar extends Component {
  render() {
    const { activeFilters, toggleFilterBar } = this.props;
    return (
      <div className="container container__events--bread-crumb-bar">
        <div className="row row--no-margin-bottom">
          <div className="col s12 col--no-margin-bottom">
            <div className="events-bread-crumb-bar">
              {"SHOWING: "}
              {activeFilters.sort === "All Events" ? (
                <span>{"ALL"}</span>
              ) : (
                <span>{activeFilters.sort}</span>
              )}
              {activeFilters.categories !== "" && (
                <span>
                  {" "}
                  <span
                    onClick={() => toggleFilterBar("categories")}
                    className="linkable-bread-crumb"
                  >
                    {activeFilters.categories}
                  </span>
                </span>
              )}
              {" EVENTS"}
              {activeFilters.dates !== "" && (
                <span>
                  {" FOR "}
                  <span
                    onClick={() => toggleFilterBar("dates")}
                    className="linkable-bread-crumb"
                  >
                    {activeFilters.dates}
                  </span>
                </span>
              )}
              {" IN "}
              <span
                onClick={() => toggleFilterBar("location")}
                className="linkable-bread-crumb"
              >
                {activeFilters.location}
              </span>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    activeFilters: state.eventFilters.breadcrumb,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    toggleFilterBar: (filterTab) => {
      dispatch(toggleFilterBar(filterTab));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EventsBreadCrumbBar);
