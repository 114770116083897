import moment from "moment-timezone";

export default function dateMatch(date, condition = "today", timezone) {
  if (!date) return false;
  const today = moment.tz(timezone).startOf("day");
  switch (condition) {
    case "today": {
      const endOfToday = today.clone().endOf("day")
      return moment.tz(date, timezone).isBetween(today, endOfToday);
    }
    case "tomorrow": {
      const startOfTomorrow = today.clone().add(1, "day").startOf("day")
      const endOfTomorrow = today.clone().add(1, "day").endOf("day")
      return moment
        .tz(date, timezone)
        .isBetween(startOfTomorrow, endOfTomorrow);
    }
    case "this_weekend": {
      const saturday = today
        .clone()
        .startOf("isoWeek")
        .add(5, "day")
        .startOf("day");
      const sunday = today
        .clone()
        .startOf("isoWeek")
        .add(6, "day")
        .endOf("day");
      return moment.tz(date, timezone).isBetween(saturday, sunday);
    }
    case "this_week": {
      const firstDayOfWeek = today.clone().startOf("isoWeek").startOf("day");
      const lastDayOfWeek = today.clone().endOf("isoWeek").endOf("day");
      return moment
        .tz(date, timezone)
        .isBetween(firstDayOfWeek, lastDayOfWeek);
    }
    case "next_week": {
      const firstDayOfNextWeek = today
        .clone()
        .startOf("isoWeek")
        .add(1, "week")
        .startOf("day");
      const lastDayOfNextWeek = today
        .clone()
        .endOf("isoWeek")
        .add(1, "week")
      return moment
        .tz(date, timezone)
        .isBetween(firstDayOfNextWeek, lastDayOfNextWeek);
    }
    case "this_month": {
      const firstDayOfMonth = today.clone().startOf("month").startOf("day");
      const lastDayOfMonth = today.clone().endOf("month").endOf("day");
      return moment
        .tz(date, timezone)
        .isBetween(firstDayOfMonth, lastDayOfMonth);
    }
    default: {
      return false;
    }
  }
}
