import React, { Component } from "react";
import { connect } from "react-redux";
import _isEqual from "lodash/isEqual";
import _map from "lodash/map";
import _get from "lodash/get";
import _some from "lodash/some";
import _flatten from "lodash/flatten";
import _intersection from "lodash/intersection";
import _orderBy from "lodash/orderBy";
import _groupBy from "lodash/groupBy";
import moment from "moment-timezone";
import EventsGridModule from "@components/modules/common/events-grid-module";
import EventsListModule from "@components/modules/common/events-list-module";
import EventsSortBar from "@containers/events/events-sort-bar";
import EventsBreadCrumbBar from "@containers/events/events-bread-crumb-bar";
import EventsFilterBar from "@containers/events/events-filter-bar";
import dateMatch from "@utils/date-match";
import scrollIt from "@utils/scroll-it";
import { compareGrade } from "@utils/misc";
import {
  getAllCategoryFilters,
  compileLocationFilters as compileLocationFiltersUtil,
  compileCategoryFilters as compileCategoryFiltersUtil,
} from "@utils/filter-utils";
import { toggleFilterBar } from "@actions/ui";
import { eventsPaginate, storeScrollOffset } from "@actions/events-pagination";
import { EVENT_STATE_PUBLISHED, GA } from "@constants/config";
import { configureHeader } from "@actions/ui";
import { getFilters } from "@selectors/filters";
import {
  checkEventGradeMatching,
  checkEventLocationMatching,
} from "@containers/events/event-filter-utils";
import SadFace from "@assets/image/sad-face.png";
import { EVENTS_PAGINATION_SIZE, META } from "../../constants/config";
import store from "store";
import { currentLocation } from "../../selectors/filters";
import { getEventsVersionOne } from "../../utils/http_service/events";
import { hideGlobalLoader, showGlobalLoader } from "../../actions/ui";

class Events extends Component {
  constructor(props) {
    super(props);
    this.interruptor = 0
    this.canPaginate = true;
    this.state = {
      eventsFiltered: []
    }
    this.searchFilters = {
      eventCategory: [],
      date: "",
      eventGenres: [],
    }
  }

  componentDidMount = () => {
    const { configureHeader, platform, toggleFilterBar } = this.props;
    window?.fbq && window.fbq("track", "PageView");
    window?.ga && window.ga("send", "pageview", GA.PAGES.events);
    window?.addEventListener("scroll", this.onScrollHandler);

    configureHeader({
      headerVisibilityLocked: true,
      headerTheme: "white",
    });

    if (!platform.IS_MOBILE && !store.get("first_time_user")) {
      store.set("first_time_user", "no-longer");
      toggleFilterBar("categories");
    }

  };

  componentWillUnmount = () => {
    window.removeEventListener("scroll", this.onScrollHandler);
    const offset =
      document.documentElement.scrollTop || document.body.scrollTop;
    this.props.storeScrollOffset(offset);
  };

  fetchEventsRecursively = async ({ currLocation, interruptor, page, eventCategory, date, eventGenres, timezone }) => {
    try {
      const { events, length } = await getEventsVersionOne({
        rn_filters: {
          region: currLocation,
          categories: eventCategory.length ? eventCategory.join(",") : undefined,
          genres: eventGenres.length ? eventGenres.join(",") : undefined
        },
        size: EVENTS_PAGINATION_SIZE,
        page,
        from: date ? (
          ["today", "this_week", "this_month"].includes(date)
            ? moment.tz(timezone).format("YYYY-MM-DD")
            : date === "tomorrow"
              ? moment.tz(timezone).add(1, "day").format("YYYY-MM-DD")
              : date === "this_weekend"
                ? moment.tz(timezone).startOf("isoWeek").add(5, "days").format("YYYY-MM-DD")
                : moment.tz(timezone).endOf("isoWeek").format("YYYY-MM-DD")
        ) : null,
        to: date ? (
          date === "today"
            ? moment.tz(timezone).add(4, "days").format("YYYY-MM-DD")
            : date === "tomorrow"
              ? moment.tz(timezone).add(1, "day").add(4, "days").format("YYYY-MM-DD")
              : ["this_week", "this_weekend"].includes(date)
                ? moment.tz(timezone).endOf("isoWeek").add(4, "days").format("YYYY-MM-DD")
                : date === "next_week"
                  ? moment.tz(timezone).add(1, "isoWeek").endOf("isoWeek").add(4, "days").format("YYYY-MM-DD")
                  : moment.tz(timezone).endOf("month").add(4, "days").format("YYYY-MM-DD")
        ) : null,
        timezone
      })
      if (this.interruptor !== interruptor) {
        return
      }
      this.setState({
        eventsFiltered: [...this.state.eventsFiltered, ...events]
      }, () => {
        this.props.hideGlobalLoader()
        if (length < EVENTS_PAGINATION_SIZE) {
          return
        }
        this.fetchEventsRecursively({ currLocation, interruptor, page: page + 1, eventCategory, date, eventGenres, timezone })
      })
    } catch (e) {
    }
  }

  componentDidUpdate = async (prevProps) => {
    if (prevProps.currentPage !== this.props.currentPage) {
      this.canPaginate = true;
    }
    if (prevProps.filters !== this.props.filters) {
      scrollIt(0, 300);
    }
    const eventGenres = this.props.filters?.categories[0].subcategories.filter(categorie => categorie.selected).map(categorie => categorie.label)
    const eventCategory = this.props.filters?.categories[1].subcategories.filter(categorie => categorie.selected).map(categorie => categorie.label)
    const date = this.props.filters?.dates.filter(categorie => categorie.selected).map(categorie => categorie.value)[0]
    const searchFilters = {
      eventGenres: eventGenres,
      eventCategory: eventCategory,
      date: date ?? ""
    }
    if (!_isEqual(searchFilters, this.searchFilters) && this.props.currentRegion) {
      if (searchFilters.eventGenres.length !== 0 || searchFilters.date || searchFilters.eventCategory.length !== 0) {
        this.props.showGlobalLoader()
        this.searchFilters = searchFilters
        this.interruptor = this.interruptor + 1
        const currLocation = _get(this.props.currentRegion, 'value', null)
        const timezone = _get(this.props.currentRegion, 'timezone', null)
        this.setState({
          eventsFiltered: []
        }, () => {
          this.fetchEventsRecursively({ currLocation, interruptor: this.interruptor, page: 0, eventCategory, date, eventGenres, timezone })
        })
      } else {
        this.searchFilters = {
          eventCategory: [],
          date: "",
          eventGenres: [],
        }
        this.setState({ eventsFiltered: [] })
      }
    }
  };

  compileLocationFilters = (nextProps) => {
    return compileLocationFiltersUtil(nextProps.filters);
  };

  compileCategoryFilters = (nextProps) => {
    return compileCategoryFiltersUtil(nextProps.filters);
  };

  getFilteredContent = (content, filters) => {
    const locationFilters = this.compileLocationFilters(this.props);
    const categoryFilters = this.compileCategoryFilters(this.props);
    const c = content ? content : this.props.events;
    const f = filters ? filters : this.props.filters;
    // const currentTime = moment().startOf('day');

    let eventDate = undefined;

    let dailyEventBatch;
    const eventsArray = [];

    c.filter((event) => {
      // EXCLUDE ALL EVENTS WHO HAVE NO TAG ASSIGNED (INVALID)
      if (!event.tags) return false;
      if (event.event_state !== EVENT_STATE_PUBLISHED) return false;
      const eventTags = event.tags.split(",");

      // CHECK IF EVENT IS PART OF CURRENT SORT
      if (!checkEventGradeMatching(event, filters)) {
        return false;
      }

      // CHECK IF EVENT LOCATION IS MATCHING
      const locationMatch = checkEventLocationMatching(
        event,
        eventTags,
        locationFilters
      );

      // CHECK IF EVENT DATE IS MATCHING
      let isDateMatch = false;
      const dates = f.dates.filter((date) => date.selected);
      isDateMatch = _some(dates, (date) =>
        dateMatch(event.start_time, date.value, event.timezone)
      );

      // CHECK EVENT CATEGORIES BY LOCATIONS
      let isValid = false;
      if (dates.length > 0 && !isDateMatch) {
        isValid = false;
      } else if (locationMatch) {
        if (categoryFilters.length <= 0) {
          isValid = true;
        } else {
          const matchingCategoryTags = _intersection(
            categoryFilters,
            _map(eventTags, (item) => item.toLowerCase())
          );
          if (matchingCategoryTags.length >= 1) {
            isValid = true;
          }
        }
      }

      if (isValid) {
        if (eventDate === undefined) {
          eventDate = moment.tz(event.start_time, event.timezone);
          event.stamp = true;
          dailyEventBatch = [];
          dailyEventBatch.push(event);
        } else {
          if (!eventDate.isSame(event.start_time, "day")) {
            this.mergeBatchedEvents(eventsArray, dailyEventBatch);

            eventDate = moment.tz(event.start_time, event.timezone);
            event.stamp = true;

            dailyEventBatch = [];
            dailyEventBatch.push(event);
          } else {
            event.stamp = false;
            dailyEventBatch.push(event);
          }
        }

        return true;
      }

      return false;
    });

    if (dailyEventBatch && dailyEventBatch.length > 0) {
      this.mergeBatchedEvents(eventsArray, dailyEventBatch);
    }

    const {
      breadcrumb: { sort: sortType },
    } = filters;

    if (sortType === "All Events") {
      const groupedData = _groupBy(
        _orderBy(_flatten(eventsArray), "event_grade", "desc"),
        (item) => moment.tz(item.start_time, item.timezone).format("YYYY-MM-DD")
      );

      const groupedDataKeys = Object.entries(groupedData).sort(
        ([key1], [key2]) => (moment(key1).isSameOrAfter(key2) ? 1 : -1)
      );
      const result = groupedDataKeys.reduce((acc, [key, value]) => {
        return acc.concat(value);
      }, []);

      return result;
    } else {
      return _flatten(eventsArray);
    }
  };

  mergeBatchedEvents = (source, batch) => {
    batch.sort(compareGrade).forEach((item, index) => {
      if (index === 0) item.stamp = true;
      else item.stamp = false;
    });
    source.push(batch);
  };

  onScrollHandler = () => {
    const { events, filters } = this.props;
    const filteredContent = this.getFilteredContent(this.state.eventsFiltered.length ? this.state.eventsFiltered : events, filters);
    if (!filteredContent) return;
    if (
      window.innerHeight + window.scrollY >= document.body.offsetHeight - 400 &&
      this.props.currentPage <
      Math.ceil(filteredContent.length / EVENTS_PAGINATION_SIZE) &&
      this.canPaginate
    ) {
      this.canPaginate = false;
      this.props.paginate();
    }
  };

  renderEventGridModules = (filteredContent) => {
    const eventGrids = [];
    for (let i = 0; i <= this.props.currentPage; i++) {
      eventGrids.push(
        <EventsGridModule
          key={"eventgrid-" + i}
          events={
            filteredContent
              ? filteredContent.slice(
                i * EVENTS_PAGINATION_SIZE,
                (i + 1) * EVENTS_PAGINATION_SIZE
              )
              : []
          }
          categoryFilters={getAllCategoryFilters(this.props.filters)}
        />
      );
    }
    return eventGrids;
  };

  renderEventListModules = (filteredContent) => {
    const eventGrids = [];
    for (let i = 0; i <= this.props.currentPage; i++) {
      eventGrids.push(
        <EventsListModule
          key={"eventgrid-" + i}
          prevDate={filteredContent[(i * EVENTS_PAGINATION_SIZE) - 1]?.start_time}
          events={
            filteredContent
              ? filteredContent.slice(
                i * EVENTS_PAGINATION_SIZE,
                (i + 1) * EVENTS_PAGINATION_SIZE
              )
              : []
          }
          categoryFilters={getAllCategoryFilters(this.props.filters)}
        />
      );
    }
    return eventGrids;
  };

  render() {
    const { events, filters, toggleFilterBar, listViewType } = this.props;
    const filteredContent = this.getFilteredContent(this.state.eventsFiltered.length ? this.state.eventsFiltered : events, filters);
    return (
      <div className="events-container">
        <EventsSortBar />
        <EventsBreadCrumbBar />
        {filteredContent && filteredContent.length <= 0 ? (
          <div className="row">
            <div className="col s12">
              <div className="no-events__content">
                <div className="no-events__graphic">
                  <img alt="face" src={SadFace} />
                </div>
                <div className="no-events__headline">{"No Results?"}</div>
                <div className="no-events__discovery-cta">
                  {"Try broadening your "}
                  <span
                    className="link"
                    onClick={() => {
                      toggleFilterBar("categories");
                    }}
                  >
                    filters
                  </span>
                  {"."}
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="container container__events">
            {listViewType === "list"
              ? this.renderEventListModules(filteredContent)
              : this.renderEventGridModules(filteredContent)}
          </div>
        )}
        <EventsFilterBar />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    filters: getFilters(state),
    platform: state.platform,
    listViewType: state.ui.listViewType,
    currentPage: state.events.currentPage,
    currentScrollOffset: state.events.currentScrollOffset,
    events: state.events.data,
    currentRegion: currentLocation(state),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    configureHeader: (val) => {
      dispatch(configureHeader(val));
    },
    toggleFilterBar: (filterTab) => {
      dispatch(toggleFilterBar(filterTab));
    },
    paginate: () => {
      dispatch(eventsPaginate());
    },
    storeScrollOffset: (offset) => {
      dispatch(storeScrollOffset(offset));
    },
    showGlobalLoader: () => {
      dispatch(showGlobalLoader())
    },
    hideGlobalLoader: () => {
      dispatch(hideGlobalLoader())
    },
  };
};

const EventsRedux = connect(mapStateToProps, mapDispatchToProps)(Events);

export default EventsRedux;

export const Head = () => (
  <>
    <title>{`${META.title} | Events`}</title>
    <link rel="canonical" href={`${process.env.GATSBY_TTF_SITE_URL}/events`} />
    <meta property="og:title" content={`${META.title} | Events`} />
  </>
);
