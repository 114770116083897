import React from "react";

export function renderFilterCheckboxes(data, cb, uppercase) {
  return data.map((item) => {
    return (
      <label
        key={"filter-checkbox-" + item.value}
        className="filter-section__checkbox"
      >
        <input
          type="checkbox"
          label={item.label}
          value={item.value}
          checked={item.selected}
          onChange={cb}
        />
        <span className="checkbox__control-indicator"></span>
        <span
          className={`checkbox__label ${
            uppercase ? "checkbox__label__uppercase" : ""
          }`}
        >
          {item.label}
        </span>
      </label>
    );
  });
}
