import PropTypes from "prop-types";
import React, { Component } from "react";
import { renderFilterCheckboxes } from "@utils/render-filter-checkboxes";
import { GA } from "@constants/config";
import { isWindowDefined } from "../../assets/third-party/blast";

class LocationFilter extends Component {
  static propTypes = {
    data: PropTypes.array,
    setLocation: PropTypes.func.isRequired,
  };

  componentDidMount = () => {
    if (isWindowDefined) {
      window.ga &&
        window.ga(
          "send",
          "event",
          GA.CATEGORY.filter,
          GA.ACTIONS.click,
          GA.EVENTS.location_filter
        );
    }
  };

  renderRegions = () => {
    let selectedValue = "select-city";
    let regions = this.props.data.map((region, i) => {
      if (region.selected) {
        selectedValue = region.value;
        return (
          <option key={"region-select-" + region.value} value={region.value}>
            {region.label}
          </option>
        );
      }

      return (
        <option key={"region-select-" + region.value} value={region.value}>
          {region.label}
        </option>
      );
    });

    regions.unshift(
      <option value="select-city" key={"region-select-default"} disabled>
        {"Select Region"}
      </option>
    );

    return (
      <select defaultValue={selectedValue} onChange={this.onRegionChange}>
        {regions}
      </select>
    );
  };

  onRegionChange = (e) => {
    sessionStorage.setItem("chosen_region", e.target.value);

    this.props.setLocation({
      type: "region",
      value: e.target.value,
      selected: true,
    });
  };

  onNeighborhoodChange = (e) => {
    this.props.setLocation({
      type: "neighborhood",
      value: e.target.value,
      selected: e.target.checked,
    });
    window &&
      window.ga &&
      window.ga(
        "send",
        "event",
        GA.CATEGORY.filter,
        e.target.checked ? GA.ACTIONS.select : GA.ACTIONS.deselect,
        e.target.value
      );
  };

  renderNeighborhoods = () => {
    const currentLocation = this.props.data.find(
      (location) => location.selected === true
    );

    if (currentLocation && currentLocation.neighborhoods.length > 0) {
      const neighborhoods = renderFilterCheckboxes(
        currentLocation.neighborhoods,
        this.onNeighborhoodChange
      );

      return (
        <div className="col col--nbh s12 m12 l8 offset-l1">
          <div className="filter-section__caption">{"NEIGHBORHOODS"}</div>
          <div className="filter-section__neighborhoods">{neighborhoods}</div>
        </div>
      );
    }

    return null;
  };

  render() {
    return (
      <div className="row filter-bar__panel">
        <div className="col col--region s10 m5 l3" style={{ paddingLeft: 40 }}>
          <div className="filter-section__caption">{"REGION"}</div>
          <div className="filter-section__region">{this.renderRegions()}</div>
        </div>
      </div>
    );
  }
}

export default LocationFilter;
