import {
  EVENTS_PAGINATE,
  EVENTS_PAGINATE_RESET,
  EVENTS_SCROLL_OFFSET,
} from "@constants/action-types";

export function eventsPaginate() {
  return {
    type: EVENTS_PAGINATE,
  };
}

export function eventsPaginateReset() {
  return {
    type: EVENTS_PAGINATE_RESET,
  };
}

export function storeScrollOffset(offset) {
  return {
    type: EVENTS_SCROLL_OFFSET,
    offset,
  };
}
